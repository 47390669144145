<template>
  <div>
    <!-- Data Table -->
    <div
      class="d-block text-center"
      v-if="!tabledata.length && !loading"
    >
      <img
        src="@/assets/images/no-data.png"
        width="150"
      />
      <h5 class="mt-2">No Record Found</h5>
    </div>
    <b-table
      :items="tabledata"
      :fields="fields"
      id="tabledata"
      :busy="loading"
      head-variant="light"
      responsive
      hover
      v-else
    >
      <template #table-busy>
        <div class="text-center text-primary mt-5 mb-1">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(chName)="data">
        <div style="width: 150px;">
          <a
            :href="data.item.media_type === 1 ? data.item.channel_link : data.item.insta_link"
            target="_blank"
          >
            <strong style="text-transform: capitalize;">{{
            data.item.media_type === 1 ? data.item.channel_name :
              data.item.insta_name
          }}</strong>
          </a>
          <br />
          <b-badge
            variant="danger"
            v-if="data.item.status === 2"
          >
            Rejected
          </b-badge>
          <b-badge
            style="background:teal"
            v-else-if="data.item.status === 3"
          >
            Shortlisted
          </b-badge>
          <b-badge
            :variant="`${data.item.status === 1 ? 'success' : 'warning'}`"
            v-else
          >
            {{data.item.status === 1 ? 'Approved' : 'Pending'}}
          </b-badge>
        </div>
      </template>
      <!-- <template #cell(chName)="data">
        <a :href="data.item.media_type === 1 ? data.item.channel_link : data.item.insta_link" target="_blank">
          <strong style="text-transform: capitalize;">{{ data.item.media_type === 1 ? data.item.channel_name : data.item.insta_name  }}</strong>
        </a>
        <span v-if="!data.item.channel_link && !data.item.channel_link">
          NA
        </span>
      </template> -->
      <template #cell(add_time)="data">
        <div style="width: 70px;">
          {{ data.value | dateFormat}}
        </div>
      </template>
      <!-- <template #cell(media_type)="data">
        {{ data.value | platform}}
      </template> -->
      <template #cell(campaign_type)="data">
        {{ data.value | campaignType}}
      </template>
      <template #cell(content)="data">
        <div v-if="data.item.media_type===1">
          {{ data.item.content_type | youtube}}
        </div>
        <div v-else>{{ data.item.content_type | instagram}} </div>
      </template>
      <template #cell(budget)="data">
        {{ data.value | formatNumber}}
      </template>
      <template #cell(min_follower)="data">
        {{ data.value | formatNumber}}
      </template>
      <!-- <template #cell(campaign_status)="data">
        {{ data.value | campaignStatus}}
      </template> -->
      <template #cell(fans)="data">
        <div v-if="data.item.media_type === 1">
          {{data.item.subscribers | formatNumber }}
        </div>
        <div v-else>{{ data.item.followers | formatNumber}} </div>
      </template>
      <template #cell(media)="data">
        <div v-if="data.item.media_type === 1">
          {{data.item.total_video | formatNumber }}
        </div>
        <div v-else>{{ data.item.total_media  | formatNumber}} </div>
      </template>
      <template #cell(action)="data">
        <!-- <b-button
          pill
          size="sm"
          class="mr-2"
          style="width:100px;border: 1px solid #28a745;"
          :disabled="data.item.status === 1 ? true : false"
          variant="outline-success"
          @click="updateStatus(data.item, 1)"
        >{{data.item.status === 1 ? 'Approved' : 'Approve'}}</b-button>
        <b-button
          pill
          size="sm"
          style="width:100px;border: 1px solid #dc3545;"
          :disabled="data.item.status === 2 ? true : false"
          variant="outline-danger"
          @click="updateStatus(data.item, 2)"
        > {{data.item.status === 2 ? 'Rejected' : 'Reject'}}</b-button> -->
        <b-dropdown
          variant="outline-white"
          no-caret
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical"></b-icon>
          </template>
          <b-dropdown-item
            @click="updateStatus(data.item, 3)"
            :disabled="data.item.status === 3"
          >
            <b-icon
              style="color:teal"
              icon="person-plus"
            ></b-icon>
            {{ data.item.status === 3 ? 'Shortlisted' : 'Shortlist' }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="updateStatus(data.item, 1)"
            :disabled="data.item.status === 1"
          >
            <b-icon
              variant="success"
              icon="person-check"
            ></b-icon>
            {{ data.item.status === 1 ? 'Approved' : 'Approve' }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="updateStatus(data.item, 2)"
            :disabled="data.item.status === 2"
          >
            <b-icon
              variant="danger"
              icon="person-x"
            ></b-icon>
            {{ data.item.status === 2 ? 'Rejected' : 'Reject' }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination
      v-if="total > 20 && !loading"
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      align="center"
      pills
      @input="handlePageClick"
    ></b-pagination>
  </div>
</template>

<script>
import { influcampaignList, influcampaignstatus } from '@/api/campaign'
export default {
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      tabledata: [],
      pendingApproval: null,
      influencer_remark: null,
      client_remark: null,
      loading: true,
      total: 0,
      filter: {
        p: 1,
        size: 20
      },
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'chName', label: 'Channel Name' },
        { key: 'name', label: 'Influencer Name' },
        // { key: 'media_type', label: 'Platform' },
        { key: 'fans', label: 'Followers' },
        { key: 'media', label: 'Total Media' },
        { key: 'campaign_type', label: 'Campaign Type' },
        { key: 'content', label: 'Content Type' },
        { key: 'budget', label: 'Campaign Budget' },
        { key: 'min_follower', label: 'Campaign Min Follower' },
        { key: 'add_time', label: 'Add time' },
        'action'
      ]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    async getData () {
      this.loading = true
      this.filter.campaignId = this.$route.params.campaignId
      this.filter.platform = parseInt(this.$route.params.platform)
      const response = await influcampaignList(this.filter)
      this.loading = false
      if (response.succ) {
        this.total = response.totaluser
        this.tabledata = response.data
        this.pendingApproval = this.tabledata.filter(value => value.status === 0)
        const influencerLength = this.pendingApproval.length
        this.$emit('influencerPendingdata', influencerLength)
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    async updateStatus (item, status) {
      try {
        this.$bvModal.msgBoxConfirm(<div><b-form-textarea v-show={status !== 3} size="md" rows="4" placeholder="Remark For Influencer" v-model={this.influencer_remark}></b-form-textarea><b-form-textarea v-show={status !== 3} class="mt-4" size="md" rows="4" placeholder="Remark For Brand" v-model={this.client_remark}></b-form-textarea></div>, {
          title: status === 1 ? 'Are you sure want to approve?' : (status === 2 ? 'Are you sure want to reject?' : 'Are you sure want to shortlist?'),
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'md',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            influencer_remark: this.influencer_remark,
            client_id: this.$route.query.client,
            client_remark: this.client_remark,
            actionUser: window.localStorage.getItem('user-name'),
            id: item.id,
            influencer_id: item.uuid,
            campaign_id: item.campaign_id,
            media_id: item.media_id,
            media_type: item.media_type,
            campaign_type: item.campaign_type,
            content_type: item.content_type,
            status: status,
            channelUrl: item.media_type === 1 ? item.channel_link : item.insta_link,
            influencer_name: item.name,
            channel_name: item.media_type === 1 ? item.channel_name : item.insta_name,
            channelId: item.media_type === 1 ? item.channel_link.replace('https://www.youtube.com/channel/', '') : item.insta_link.replace('https://www.instagram.com/', '')
          }
          if (value) {
            this.loading = true
            const response = await influcampaignstatus(data)
            this.loading = false
            if (response.succ) {
              this.$bvToast.toast(status === 1 ? 'Approved successfully' : (status === 2 ? 'Rejected successfully' : 'Shortlisted'), {
                title: 'Status',
                variant: status === 1 ? 'success' : (status === 2 ? 'danger' : 'info'),
                autoHideDelay: 2000,
                solid: true
              })
              this.influencer_remark = null
              this.client_remark = null
              this.getData()
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Status error!',
                variant: 'danger',
                solid: true
              })
              this.influencer_remark = null
              this.client_remark = null
            }
          }
        }).catch((e) => {
          console.log('cancel update.', e)
        })
      } catch (error) {
        console.log(error)
        this.influencer_remark = null
        this.client_remark = null
      }
    }
  }
}
</script>

<style scoped>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
</style>
