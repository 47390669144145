<template>
  <div>
    <!-- Filter Form -->
    <div class="d-flex align-items-start filter-form">
      <div>
        <b-form
          class="mb-2"
          inline
        >
          <b-overlay
            :show="exportLoading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
            @hidden="!exportLoading"
          >
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="campaignId"
              :options="campaignList"
              value-field="campaign_id"
              text-field="campaign_name"
              @change="getInfluencer()"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Please select campaign</b-form-select-option>
              </template>
            </b-form-select>
          </b-overlay>
          <b-button
            pill
            class="mt-lg-0 mt-md-2 mr-sm-2"
            variant="primary"
            :disabled="!tabledata.length && !loading"
            @click="submitInfluencer()"
          >Submit {{platform === 1 ? 'YouTuber' : 'Instagrammer'}}</b-button>
        </b-form>
      </div>
    </div>
    <hr />
    <!-- Data Table -->
    <div
      class="d-block text-center"
      v-if="!tabledata.length && !loading"
    >
      <img
        src="@/assets/images/no-data.png"
        width="150"
      />
      <h5 class="mt-2">No Record Found</h5>
    </div>
    <b-table
      :items="tabledata"
      :fields="platform===1?fieldsYT:fieldsInst"
      id="tabledata"
      :busy="loading"
      head-variant="light"
      responsive
      hover
      ref="selectableTable"
      selectable
      @row-selected="onRowSelected"
      v-else
    >
      <template #table-busy>
        <div class="text-center text-primary mt-5 mb-1">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(basic)="data">
        <a
          :href="platform === 1 ? data.item.channel_link : data.item.insta_link "
          target="_blank"
          class="meta d-flex justify-content-start align-items-center"
        >
          <img
            v-if="data.item.avatar&&platform === 1"
            :src="data.item.avatar"
          />
          <img
            v-else
            :src="`https://ui-avatars.com/api/?background=F54153&color=fff&name=${platform === 1?data.value.channel_name:data.value.insta_name}`"
          />
          <p>
            <strong>{{ platform === 1 ? data.item.channel_name : data.item.insta_name }}</strong> <br>
            <b-badge
              pill
              variant="info"
              v-if="data.item.category"
            >{{ data.item.category }}</b-badge>
          </p>
        </a>
      </template>
      <template #cell(language)="data">
        {{ data.value || 'NA' }}
      </template>
      <template #cell(gender)="data">
        {{ data.value || 'NA' }}
      </template>
      <template #cell(subscribers)="data">
        {{ data.value | formatNumber}}
      </template>
      <template #cell(yt_category)="data">
        {{ data.value | ytcategory}}
      </template>
      <template #cell(total_view)="data">
        {{ data.value | formatNumber}}
      </template>
      <template #cell(latest_avg_view)="data">
        {{ data.value | formatNumber}}
      </template>
      <template #cell(eng_rate)="data">
        {{ data.value ? `${data.value}%` : 'NA' }}
      </template>
      <template #cell(followers)="data">
        {{ data.value | formatNumber}}
      </template>
      <!-- <template #cell(is_business)="data">
        {{ data.value | accountType}}
      </template> -->
      <template #cell(total_media)="data">
        {{ data.value | formatNumber}}
      </template>
      <template #cell(is_verify)="data">
        {{ data.value | verify}}
      </template>
      <template #cell(is_page)="data">
        {{ data.value | page}}
      </template>
    </b-table>
  </div>
</template>

<script>
import { othercampaigninflu, campaignSelect, assigninfluCampaign } from '@/api/campaign'
export default {
  props: {
    CampaignType: {
      type: Number,
      default: () => null
    },
    ContentType: {
      type: Number,
      default: () => null
    }
  },
  data () {
    return {
      tabledata: [],
      exportLoading: true,
      loading: false,
      campaignList: [],
      fieldsInst: [
        'Selected',
        { key: 'index', label: 'Sr.' },
        { key: 'basic', label: 'Channel' },
        'language',
        'gender',
        { key: 'followers', label: 'Followers' },
        { key: 'total_media', label: 'Total Media' },
        // { key: 'is_business', label: 'Account Type' },
        { key: 'is_verify', label: 'Account Verify' },
        { key: 'is_page', label: 'Account Page' }
      ],
      fieldsYT: [
        'Selected',
        { key: 'index', label: 'Sr.' },
        { key: 'basic', label: 'Channel' },
        'language',
        'gender',
        { key: 'subscribers', label: 'Subscribers' },
        { key: 'yt_category', label: 'YouTube Category' },
        { key: 'total_video', label: 'Total Posts' },
        { key: 'total_view', label: 'Total Views' },
        { key: 'latest_avg_view', label: 'Avg Views' },
        { key: 'eng_rate', label: 'Eng. Rate' }
      ],
      campaignId: null,
      platform: null,
      influencerSelection: []
    }
  },
  mounted () {
    this.platform = parseInt(this.$route.params.platform)
    this.campaignData()
  },
  methods: {
    onRowSelected (items) {
      const ids = items.map(item => { return item.id })
      this.influencerSelection = ids
    },
    async getInfluencer () {
      this.tabledata = []
      if (this.influencerSelection.length) {
        this.$refs.selectableTable.clearSelected()
      }
      this.loading = true
      const data = {
        campaignId: this.campaignId,
        platform: this.platform
      }
      const response = await othercampaigninflu(data)
      if (response.succ) {
        this.tabledata = response.data
        setTimeout(() => {
          this.tabledata.forEach((row, index) => {
            this.$refs.selectableTable.selectRow(index)
          })
        }, 50)
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async campaignData () {
      const data = {
        campaignId: this.$route.params.campaignId,
        platform: this.platform
      }
      const response = await campaignSelect(data)
      if (response.succ) {
        this.campaignList = response.data
        this.exportLoading = false
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    async submitInfluencer () {
      if (!this.influencerSelection.length) {
        this.$bvToast.toast(`Please select ${this.platform === 1 ? 'youtuber' : 'instagrammer'}`, {
          title: 'Add channel error!',
          variant: 'danger',
          autoHideDelay: 1000,
          solid: true
        })
        return
      }
      try {
        this.$bvModal.msgBoxConfirm(`Are are you sure want to add ${this.platform === 1 ? 'youtuber' : 'instagrammer'}`, {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            actionUser: window.localStorage.getItem('user-name'),
            campaignId: this.$route.params.campaignId,
            influIds: this.influencerSelection,
            media_type: this.platform,
            campaign_type: this.CampaignType,
            content_type: this.ContentType
          }
          if (value) {
            const response = await assigninfluCampaign(data)
            if (response.succ) {
              this.$bvToast.toast(`${this.platform === 1 ? 'YouTuber' : 'Instagrammer'} added successfully, please refresh the page!`, {
                title: 'Added Channel',
                variant: 'success',
                autoHideDelay: 3000,
                solid: true
              })
              setTimeout(() => {
                this.$emit('submitdata')
              }, 2000)
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Add channel error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch((error) => {
          console.log('cancel add.', error)
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style datad>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
</style>
