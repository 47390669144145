<template>
  <div>
    <!-- Filter Form and snapshot-->
    <div v-if="campaignData.campaign_name">

      <div class="d-flex mb-4">
        <!-- <b-img rounded class="mr-3" height="130" src="https://test-client.soocel.com/public/client-cover/client-cover-5276c11104d11cca217fd14a2838a0a5.jpg?v=1663139408818"></b-img> -->

        <div
          style="width: 100%"
          class="d-flex flex-column"
        >
          <div
            class="d-flex align-items-center"
            style="width: 100%"
          >
            <b-icon
              variant="primary"
              :icon="`${campaignData.promo_platform === 1 ? 'youtube' : 'instagram'}`"
              class="text-danger mr-2 mb-2"
              font-scale="2.4"
            ></b-icon>
            <h5 class="mr-2">
              {{ campaignData.campaign_name }} </h5>
            <!-- <b-badge
                :variant="campaignStatusVarient(campaignData.campaign_status)"
                class="ml-2"
              >{{ campaignData.campaign_status | campaignStatus }}</b-badge> -->
            <b-badge
              variant="warning"
              class="mb-1"
              pill
              v-if="campaignData.campaign_status === 0"
            >
              <b-icon icon="exclamation-circle"></b-icon>
              {{ campaignData.campaign_status | campaignStatus}}
            </b-badge>
            <b-badge
              variant="success"
              class="mb-1"
              pill
              v-if="campaignData.campaign_status === 1"
            >
              <b-spinner
                type="grow"
                label="Spinning"
                style="width: 0.4rem; height: 0.4rem;margin-bottom:2px;"
              ></b-spinner>
              <!-- <b-icon icon="exclamation-circle"></b-icon> -->
              {{ campaignData.campaign_status | campaignStatus}}
            </b-badge>
            <b-badge
              variant="danger"
              class="mb-1"
              pill
              v-if="campaignData.campaign_status === 2"
            >
              <b-icon icon="x-circle"></b-icon>
              {{ campaignData.campaign_status | campaignStatus}}
            </b-badge>
            <b-badge
              variant="info"
              class="mb-1"
              pill
              v-if="campaignData.campaign_status === 4"
            >
              <b-icon icon="check-circle"></b-icon>
              {{ campaignData.campaign_status | campaignStatus}}
            </b-badge>
            <div class="d-flex justify-content-end align-items-start ml-auto">
              <b-form inline>
                <b-btn
                  class="mr-2"
                  variant="primary"
                  size="sm"
                  @click="openChannalModal"
                >Approved Channel</b-btn>
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="campStatus"
                  v-b-tooltip.hover.v-primary.top
                  :title="campaignData.campaign_status === 2 ? this.remarkLast : null"
                  :options="campaignStatusOptions"
                  @change="changeStatus()"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Status</b-form-select-option>
                  </template>
                </b-form-select>

                <b-button
                  @click="campaignPost()"
                  variant="light"
                >
                  <b-icon icon="arrow-clockwise"></b-icon>
                </b-button>

                <b-overlay
                  :show="exportLoading"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                  @hidden="!exportLoading"
                >
                  <b-dropdown
                    class="ml-auto"
                    variant="outline-white"
                    right
                    no-caret
                  >
                    <template #button-content>
                      <b-icon icon="list"></b-icon>
                    </template>
                    <b-dropdown-item @click="postModalAdd()">
                      <b-icon icon="plus-circle"></b-icon>
                      Add Post
                    </b-dropdown-item>
                    <b-dropdown-item @click="assignInfluencer()">
                      <b-icon icon="person-plus"></b-icon>
                      <!-- {{platform === 1 ? 'Assign YouTuber' : 'Assign Instagrammer' }} -->
                      Assign Influencer
                    </b-dropdown-item>
                    <b-dropdown-item @click="copyCampaign()">
                      <b-icon icon="file-earmark-post"></b-icon>
                      Copy from other campaign
                    </b-dropdown-item>
                    <b-dropdown-item @click="exportdata()">
                      <b-icon icon="download"></b-icon>
                      Export
                    </b-dropdown-item>
                  </b-dropdown>
                </b-overlay>
              </b-form>
            </div>
          </div>

          <b-row class="infocard ml-1">
            <p>
              <span class="label">Add Time:</span>
              <small>{{ campaignData.add_time | dateFormat }}</small>
            </p>
            <p>
              <span class="label">Timeline:</span>
              <small>{{ campaignData.start_date | dateFormat }} ~ {{ campaignData.end_date | dateFormat}}
              </small>
            </p>
            <p class="d-flex">
              <span class="mr-2">Live:</span>
              <b-form-checkbox
                switch
                :disabled="campaignData.campaign_status === 1 || campaignData.campaign_status === 4 ? false : true"
                v-model="campaignData.web_show"
                name="check-button"
                @change="webshowUpdate(campaignData)"
              >
              </b-form-checkbox>
            </p>
          </b-row>

          <b-row
            class="mt-2 mb-4 infocard statBox"
            style="width:100%"
          >
            <b-col cols="3">
              <strong>Brand Details</strong>
              <p>
                <span class="label">Brand: </span>
                <small>
                  <a
                    class="link"
                    v-bind:href="campaignData.product_link"
                    target="_blank"
                  >{{ campaignData.company_name }}</a>
                </small>
              </p>
              <p>
                <span class="label">Name: </span>
                <small>{{ campaignData.name }}</small>
              </p>
              <p>
                <span class="label">Phone: </span>
                <small>{{ campaignData.mobile }}</small>
              </p>
              <p>
                <span class="label">Email: </span>
                <small>{{ campaignData.email }}</small>
              </p>
            </b-col>
            <b-col cols="3">
              <strong>Overview</strong>
              <p>
                <span class="label">Campaign Type: </span>
                <small>{{ campaignData.campaign_type | campaignType }}</small>
              </p>
              <p>
                <span class="label">Content Type: </span>
                <small v-if="campaignData.promo_platform === 1">
                  {{ campaignData.content_type | youtube}}
                </small>
                <small v-else>{{ campaignData.content_type | instagram}} </small>
              </p>
              <p>
                <span class="label">Budget: </span>
                <small>{{ campaignData.budget | formatNumber}}</small>
              </p>
              <p>
                <span class="label">Followers Range: </span>
                <small>{{ campaignData.min_follower | formatNumber }} ~ {{ campaignData.max_follower | formatNumber }}</small>
              </p>
            </b-col>
            <b-col cols="2">
              <p>
                <strong>Categories</strong> <br />
                <small v-if="campaignData.category != '' && campaignData.category != null">
                  <span
                    v-for="value in campaignData.category.split(',')"
                    :key="value"
                  >
                    {{ value }},
                  </span>
                </small>
              </p>
            </b-col>
            <b-col cols="2">
              <p>
                <strong>Language</strong> <br />
                <small v-if="campaignData.language != '' && campaignData.language != null">
                  <span
                    v-for="value in campaignData.language.split(',')"
                    :key="value"
                  >
                    {{ value }},
                  </span>
                </small>
              </p>
            </b-col>
            <b-col cols="2">
              <p>
                <strong>Gender</strong> <br />
                <small v-if="campaignData.gender != '' && campaignData.gender != null">
                  <span
                    v-for="value in campaignData.gender.split(',')"
                    :key="value"
                  >
                    {{ value }},
                  </span>
                </small>
              </p>
            </b-col>
          </b-row>

          <div class="stats d-flex">
            <div class="statBox">
              <b-icon
                font-scale="1"
                :icon="`${platform === 1 ? 'eye' : 'heart'}`"
                variant="primary"
                class="mr-2"
              ></b-icon>
              <strong>{{(platform === 1 ? (totalViews ? totalViews : 0) : (likeCount ? likeCount : 0)) | formatNumber}}</strong>
              <br />
              <small>{{ platform === 1 ? 'Total Views' : 'Total Likes'}}</small>
            </div>

            <div class="statBox">
              <b-icon
                font-scale="1"
                icon="graph-up"
                variant="primary"
                class="mr-2"
              ></b-icon>
              <strong>{{platform === 1 ? engageRate + '%' : '0%'}}</strong>
              <br />
              <small>Engagement Rate</small>
            </div>

            <div class="statBox">
              <b-icon
                font-scale="1"
                icon="cash-coin"
                variant="primary"
                class="mr-2"
              ></b-icon>
              <strong>{{(cpv ? cpv : 0 ) | formatNumber}}</strong>
              <br />
              <small>{{platform === 1 ? 'Cost Per view' : ' Cost Per like'}}</small>
            </div>

            <div class="statBox">
              <b-icon
                font-scale="1"
                icon="cash"
                variant="primary"
                class="mr-2"
              ></b-icon>
              <strong>{{totalCost | formatNumber}}</strong>
              <br />
              <small>Total Cost</small>
            </div>

            <div class="statBox">
              <b-icon
                font-scale="1"
                icon="cash"
                variant="primary"
                class="mr-2"
              ></b-icon>
              <strong>{{revenue | formatNumber}}</strong>
              <br />
              <small>Total Revenue</small>
            </div>

            <div class="statBox">
              <b-icon
                font-scale="1"
                icon="wallet2"
                variant="primary"
                class="mr-2"
              ></b-icon>
              <strong>{{profit | formatNumber}}</strong>
              <br />
              <small>Total Profit</small>
            </div>
          </div>
        </div>

      </div>

    </div>

    <!-- <hr /> -->

    <!-- <h6>Active Posts</h6> -->

    <!-- Data Table -->
    <div
      class="d-block text-center"
      v-if="!tabledata.length && !loading"
    >
      <img
        src="@/assets/images/no-data.png"
        width="150"
      />
      <h5 class="mt-2">No Post Found</h5>
    </div>

    <b-table
      :items="tabledata"
      :fields="fields"
      id="tabledata"
      :busy="loading"
      head-variant="light"
      responsive
      hover
      v-else
    >
      <template #table-busy>
        <div class="text-center text-primary mt-5 mb-1">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(channel)="data">
        <a
          v-bind:href="data.item.media_type === 1 ? data.item.channel_link : data.item.insta_link"
          target="_blank"
        >
          <strong>{{data.item.channel_name}}</strong> <br>
          <!-- <span class="badge">{{ data.item.media_type | platform }}</span> -->
          <b-badge
            variant="info"
            pill
            v-if="data.item.category"
          >{{ data.item.category }}</b-badge>
        </a>
      </template>
      <template #cell(post_link)="data">
        <a
          :href="data.item.post_link"
          target="_blank"
        >
          {{data.item.post_link ? 'Post Link' : ''}}
        </a>
      </template>
      <template #cell(fans)="data">
        {{ (data.item.media_type === 1 ? data.item.subscribers : data.item.followers) | formatNumber }}
      </template>
      <template #cell(media)="data">
        {{ (data.item.media_type === 1 ? data.item.total_video : data.item.total_media) | formatNumber }}
      </template>
      <template #cell(count)="data">
        {{ (data.item.media_type === 1 ? data.item.view_count : data.item.video_view_count) | formatNumber }}
      </template>
      <template #cell(like_count)="data">
        {{ (data.value ? data.value : 0) | formatNumber }}
      </template>
      <template #cell(campaign_type)="data">
        {{ data.value| campaignType }}
      </template>
      <template #cell(gst)="data">
        {{ data.value| formatNumber }}
      </template>
      <template #cell(tds)="data">
        {{ data.value| formatNumber }}
      </template>
      <template #cell(influencer_price)="data">
        {{ data.value| formatNumber }}
      </template>
      <template #cell(add_by)="data">
        <b-badge
          :variant="data.value ? 'primary' : 'secondary' "
          pill
        >
          {{ data.value ? 'Creator' : 'Admin' }}
        </b-badge>
      </template>
      <template #cell(client_price)="data">
        {{ data.value| formatNumber }}
      </template>
      <template #cell(content)="data">
        <div v-if="data.item.media_type === 1">
          {{ data.item.content_type | youtube }}
        </div>
        <div v-else>
          {{ data.item.content_type | instagram }}
        </div>
      </template>
      <template #cell(cost)="data">
        {{ (data.item.influencer_price + data.item.tds) | formatNumber}}
      </template>
      <template #cell(video_eng_rate)="data">
        {{ data.value ? (data.value + '%') : 0}}
      </template>
      <template #cell(cpv)="data">
        {{data.item.influencer_price && data.item.view_count ? (((data.item.tds + data.item.influencer_price)/data.item.view_count) | formatNumber) : '0'}}
      </template>
      <template #cell(add_time)="data">
        {{ data.value | dateFormat}}
      </template>
      <template #cell(action)="data">
        <b-dropdown
          variant="outline-white"
          no-caret
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical"></b-icon>
          </template>
          <b-dropdown-item
            :disabled="data.item.admin_status===5"
            v-if="data.item.post_link"
            @click="postApproval(data.item)"
          >
            <b-icon
              variant="success"
              icon="check-circle"
            ></b-icon>
            {{data.item.admin_status === 5 ? 'Approved' : 'Approve'}}
          </b-dropdown-item>
          <b-dropdown-item @click="openNotify(data.item)">
            <b-icon
              variant="danger"
              icon="chat-left-dots"
            ></b-icon>
            Notification
          </b-dropdown-item>
          <b-dropdown-item @click="postModalEdit(data.item)">
            <b-icon icon="pencil-square"></b-icon>
            Edit
          </b-dropdown-item>
          <b-dropdown-item @click="deletePost(data.item)">
            <b-icon icon="trash"></b-icon>
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <!-- AddPost Modal  -->
    <b-modal
      title="Add Post"
      ref="addPostModal"
      hide-footer
      centered
    >
      <validation-observer ref="addchannelrules">
        <b-form>
          <b-form-group label="Approved Channel">
            <validation-provider
              #default="{ errors }"
              name="Channel"
              rules="required"
            >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                value-field="media_id"
                text-field="channel_name"
                v-model="addPostData.media_id"
                :options="channelList"
                :state="errors.length > 0 ? false : null"
              > <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select channel</b-form-select-option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Campaign Type">
            <validation-provider
              #default="{ errors }"
              name="Campaign Type"
              rules="required"
            >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="addPostData.campaign_type"
                :options="campaignTypeOptions"
                disabled
                :state="errors.length > 0 ? false : null"
              > <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Campaign</b-form-select-option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Content Type">
            <validation-provider
              #default="{ errors }"
              name="Content Type"
              rules="required"
            >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="addPostData.content_type"
                :options="platform === 1 ? youtubeOptions : instagramOptions"
                disabled
                :state="errors.length > 0 ? false : null"
              > <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Content</b-form-select-option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Post Url">
            <validation-provider
              v-if="platform === 1 && addPostData.content_type !== 3"
              #default="{ errors }"
              name="Post Url"
              :rules="{ required: true, regex: /^https:\/\/www.youtube.com\/watch\?v=[A-Za-z0-9_-]/ }"
            >
              <b-form-input
                v-model="addPostData.post_link"
                placeholder="https://www.youtube.com/watch?v=VIDEO ID"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              v-if="platform === 1 && addPostData.content_type === 3"
              #default="{ errors }"
              name="Post Url"
              :rules="{ required: true, regex: /^https:\/\/www.youtube.com\/shorts\/[A-Za-z0-9_-]/ }"
            >
              <b-form-input
                v-model="addPostData.post_link"
                placeholder="https://www.youtube.com/shorts/SHORT ID"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              v-if="platform === 2 && addPostData.content_type === 8"
              #default="{ errors }"
              name="Post Url"
              :rules="{ required: true, regex: /(https?:\/\/(?:www\.)?instagram\.com\/reel\/([^/?#&]+)\/+$).*/ }"
            >
              <b-input-group>
                <b-form-input
                  v-model="addPostData.post_link"
                  placeholder="https://www.instagram.com/reel/REEL ID/"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <b-input-group-append>
                  <b-overlay
                    :show="exportLoading"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="!exportLoading"
                  >
                    <b-btn
                      :disabled="!addPostData.post_link"
                      v-if="platform === 2 && addPostData.content_type"
                      variant="success"
                      @click="fetchapi(addtPostData, 1)"
                    >Fetch Data</b-btn>
                  </b-overlay>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              v-if="platform === 2 && (addPostData.content_type === 6 || addPostData.content_type === 7)"
              #default="{ errors }"
              name="Post Url"
              :rules="{ required: true, regex: /(https?:\/\/(?:www\.)?instagram\.com\/p\/([^/?#&]+)\/+$).*/ }"
            >
              <b-input-group>
                <b-form-input
                  v-model="addPostData.post_link"
                  placeholder="https://www.instagram.com/p/POST ID/"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <b-input-group-append>
                  <b-overlay
                    :show="exportLoading"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="!exportLoading"
                  >
                    <b-btn
                      :disabled="!addPostData.post_link"
                      v-if="platform === 2 && addPostData.content_type"
                      variant="success"
                      @click="fetchapi(addPostData, 1)"
                    >Fetch Data</b-btn>
                  </b-overlay>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              v-if="this.platform === 2 && (addPostData.content_type === 4 || addPostData.content_type === 5)"
              #default="{ errors }"
              name="Post Url"
              :rules="{ required: true, regex: /(https?:\/\/(?:www\.)?instagram\.com\/stories\/.*\/([^/?#&]+)\/+$).*/ }
              "
            >
              <!-- without end slash
                   (https?:\/\/(?:www\.)?instagram\.com\/reel\/([^/?#&]+)+$).* -->

              <!-- with end slash
                   (https?:\/\/(?:www\.)?instagram\.com\/reel\/([^/?#&]+)\/+$).* -->

              <b-input-group>
                <b-form-input
                  v-model="addPostData.post_link"
                  placeholder="https://www.instagram.com/stories/USERNAME/STORY ID/"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <b-input-group-append>
                  <b-overlay
                    :show="exportLoading"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="!exportLoading"
                  >
                    <b-btn
                      :disabled="!addPostData.post_link"
                      v-if="platform === 2 && addPostData.content_type"
                      variant="success"
                      @click="fetchapi(addPostData, 1)"
                    >Fetch Data</b-btn>
                  </b-overlay>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Post Type"
            v-if="platform === 2"
          >
            <validation-provider
              #default="{ errors }"
              name="Post Type"
              rules="required"
            >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="addPostData.is_video"
                value-field="value"
                text-field="label"
                :options="postType"
                :state="errors.length > 0 ? false : null"
              > <template #first>
                  <b-form-select-option :value="null">Select Post Type</b-form-select-option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Likes"
            v-if="platform === 2"
          >
            <validation-provider
              #default="{ errors }"
              name="Likes"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="addPostData.like_count"
                placeholder="Enter Likes"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="View Count"
            v-if="addPostData.is_video && platform === 2"
          >
            <validation-provider
              #default="{ errors }"
              name="View Count"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="addPostData.video_view_count"
                placeholder="Enter View Count"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Influencer Price">
            <validation-provider
              #default="{ errors }"
              name="Influencer Price"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="addPostData.influencer_price"
                placeholder="Influencer latest price"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="TDS">
            <validation-provider
              #default="{ errors }"
              name="TDS"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="addPostData.tds"
                placeholder="Enter TDS"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Client Price">
            <validation-provider
              #default="{ errors }"
              name="Client Price"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="addPostData.client_price"
                placeholder="Client price"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="GST">
            <validation-provider
              #default="{ errors }"
              name="GST"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="addPostData.gst"
                placeholder="Enter GST"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="d-flex justify-content-end mt-4">
            <b-button
              pill
              class="mr-2"
              variant="danger"
              @click="cancelAdd()"
            >Cancel</b-button>
            <b-button
              pill
              class="mr-2"
              variant="primary"
              @click="resetPost()"
            >Reset</b-button>
            <b-button
              pill
              type="submit"
              variant="primary"
              @click.prevent="addPost()"
            >Add</b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- EditPost Modal  -->
    <b-modal
      title="Edit Post"
      ref="editPostModal"
      hide-footer
      centered
    >
      <validation-observer ref="editRules">
        <b-form>
          <b-form-group label="Assigned Channel">
            <validation-provider
              #default="{ errors }"
              name="channel"
            >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                value-field="media_id"
                text-field="channel_name"
                v-model="editPostData.media_id"
                :options="channelList"
                :state="errors.length > 0 ? false : null"
              > <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select channel</b-form-select-option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Campaign Type">
            <validation-provider
              #default="{ errors }"
              name="Campaign Type"
              rules="required"
            >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="editPostData.campaign_type"
                :options="campaignTypeOptions"
                disabled
                :state="errors.length > 0 ? false : null"
              > <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Campaign</b-form-select-option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Content Type">
            <validation-provider
              #default="{ errors }"
              name="Content Type"
              rules="required"
            >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="editPostData.content_type"
                :options="platform === 1 ? youtubeOptions : instagramOptions"
                disabled
                :state="errors.length > 0 ? false : null"
              > <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Content</b-form-select-option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Post Url">
            <validation-provider
              v-if="platform === 1 && editPostData.content_type !== 3"
              #default="{ errors }"
              name="Post Url"
              :rules="{ regex: /^https:\/\/www.youtube.com\/watch\?v=[A-Za-z0-9_-]/ }"
            >
              <b-form-input
                v-model="editPostData.post_link"
                placeholder="https://www.youtube.com/watch?v=VIDEO ID"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              v-if="platform === 1 && editPostData.content_type === 3"
              #default="{ errors }"
              name="Post Url"
              :rules="{ regex: /^https:\/\/www.youtube.com\/shorts\/[A-Za-z0-9_-]/ }"
            >
              <b-form-input
                v-model="editPostData.post_link"
                placeholder="https://www.youtube.com/shorts/SHORT ID"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              v-if="platform === 2 && editPostData.content_type === 8"
              #default="{ errors }"
              name="Post Url"
              :rules="{ regex: /(https?:\/\/(?:www\.)?instagram\.com\/reel\/([^/?#&]+)\/+$).*/ }"
            >
              <b-input-group>
                <b-form-input
                  v-model="editPostData.post_link"
                  placeholder="https://www.instagram.com/reel/REEL ID/"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <b-input-group-append>
                  <b-overlay
                    :show="exportLoading"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="!exportLoading"
                  >
                    <b-btn
                      :disabled="!editPostData.post_link"
                      v-if="platform === 2 && editPostData.content_type"
                      variant="success"
                      @click="fetchapi(editPostData, 2)"
                    >Fetch Data</b-btn>
                  </b-overlay>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              v-if="platform === 2 && (editPostData.content_type === 6 || editPostData.content_type === 7)"
              #default="{ errors }"
              name="Post Url"
              :rules="{ regex: /(https?:\/\/(?:www\.)?instagram\.com\/p\/([^/?#&]+)\/+$).*/ }"
            >
              <!-- without end slash
              (https?:\/\/(?:www\.)?instagram\.com\/reel\/([^/?#&]+)+$).*
              with end slash
              (https?:\/\/(?:www\.)?instagram\.com\/reel\/([^/?#&]+)\/+$).* -->
              <b-input-group>
                <b-form-input
                  v-model="editPostData.post_link"
                  placeholder="https://www.instagram.com/p/POST ID/"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <b-input-group-append>
                  <b-overlay
                    :show="exportLoading"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="!exportLoading"
                  >
                    <b-btn
                      :disabled="!editPostData.post_link"
                      v-if="platform === 2 && editPostData.content_type"
                      variant="success"
                      @click="fetchapi(editPostData, 2)"
                    >Fetch Data</b-btn>
                  </b-overlay>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              v-if="this.platform === 2 && (editPostData.content_type === 4 || editPostData.content_type === 5)"
              #default="{ errors }"
              name="Post Url"
              :rules="{ regex: /(https?:\/\/(?:www\.)?instagram\.com\/stories\/.*\/([^/?#&]+)\/+$).*/ }
              "
            >
              <b-input-group>
                <b-form-input
                  v-model="editPostData.post_link"
                  placeholder="https://www.instagram.com/stories/USERNAME/STORY ID/"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <b-input-group-append>
                  <b-overlay
                    :show="exportLoading"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="!exportLoading"
                  >
                    <b-btn
                      :disabled="!editPostData.post_link"
                      v-if="platform === 2 && editPostData.content_type"
                      variant="success"
                      @click="fetchapi(editPostData, 2)"
                    >Fetch Data</b-btn>
                  </b-overlay>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Post Type"
            v-if="platform === 2"
          >
            <validation-provider
              #default="{ errors }"
              name="Post Type"
            >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="editPostData.is_video"
                value-field="value"
                text-field="label"
                :options="postType"
                :state="errors.length > 0 ? false : null"
              > <template #first>
                  <b-form-select-option :value="null">Select Post Type</b-form-select-option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Likes"
            v-if="platform === 2"
          >
            <validation-provider
              #default="{ errors }"
              name="Likes"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="editPostData.like_count"
                placeholder="Enter Likes"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="View Count"
            v-if="editPostData.is_video && platform === 2"
          >
            <validation-provider
              #default="{ errors }"
              name="View Count"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="editPostData.video_view_count"
                placeholder="Enter View Count"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Influencer Price">
            <validation-provider
              #default="{ errors }"
              name="Influencer Price"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="editPostData.influencer_price"
                placeholder="Influencer latest price"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="TDS">
            <validation-provider
              #default="{ errors }"
              name="TDS"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="editPostData.tds"
                placeholder="Enter TDS"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Client Price">
            <validation-provider
              #default="{ errors }"
              name="Client Price"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="editPostData.client_price"
                placeholder="Client price"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="GST">
            <validation-provider
              #default="{ errors }"
              name="GST"
              :rules="{ regex: /^[0-9]{0,10}$/ }"
            >
              <b-form-input
                v-model="editPostData.gst"
                placeholder="Enter GST"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="d-flex justify-content-end mt-4">
            <b-button
              pill
              class="mr-2"
              variant="secondary"
              @click="cancelEdit()"
            >Cancel</b-button>
            <b-button
              pill
              type="submit"
              variant="primary"
              @click.prevent="updatePost()"
            >Update</b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- youtubeModal -->
    <b-modal
      title="YouTuber Selection"
      ref="youtubeModal"
      hide-footer
      :no-close-on-backdrop="true"
      centered
      size="xl"
    >
      <Youtube
        :CampaignType="campaignData.campaign_type"
        :ContentType="campaignData.content_type"
        v-on:closeYt="ytModal"
      />
    </b-modal>
    <!-- instagramModal  -->
    <b-modal
      title="Instagram Selection"
      ref="instagramModal"
      size="xl"
      hide-footer
      centered
      :no-close-on-backdrop="true"
    >
      <Instagram
        :CampaignType="campaignData.campaign_type"
        :ContentType="campaignData.content_type"
        v-on:closeInst="instModal"
      />
    </b-modal>
    <!-- campaignModal  -->
    <b-modal
      :title="platform===1 ? 'YouTuber Selection':'Instagram Selection'"
      ref="campaignModal"
      size="xl"
      hide-footer
      centered
    >
      <Campaign
        v-on:submitdata="closewindow"
        :CampaignType="campaignData.campaign_type"
        :ContentType="campaignData.content_type"
        :no-close-on-backdrop="true"
      />
    </b-modal>
    <!-- Post Notification Modal  -->
    <b-modal
      title="Notification"
      ref="notifyPostModal"
      hide-footer
      :no-close-on-backdrop="false"
      centered
      size="lg"
    >
      <template #modal-title>
        Notification
        <b-badge
          v-if="postNotify.post_url===null"
          class="ml-2"
          pill
          variant="warning"
        >
          <b-icon
            icon="exclamation-circle"
            class="mr-1"
          ></b-icon>No Post
        </b-badge>
        <b-badge
          v-if="postNotify.post_url && postNotify.admin_status===3"
          class="ml-2"
          pill
          variant="warning"
        >
          <b-icon
            icon="exclamation-circle"
            class="mr-1"
          ></b-icon>Approval Pending
        </b-badge>
        <b-badge
          v-if="postNotify.post_url && postNotify.admin_status===4"
          class="ml-2"
          pill
          variant="warning"
        >
          <b-icon
            icon="exclamation-circle"
            class="mr-1"
          ></b-icon>New Post Pending
        </b-badge>
        <b-badge
          v-if="postNotify.admin_status===5"
          class="ml-2"
          pill
          variant="success"
        >
          <b-icon
            icon="check2-circle"
            class="mr-1"
          ></b-icon>Post Approved
        </b-badge>
      </template>
      <validation-observer ref="postRules">
        <b-form>
          <b-form-group label="Last Remark For Influencer">
            <validation-provider
              #default="{ errors }"
              name="Remark"
              rules="required"
            >
              <b-form-textarea
                v-model="postNotify.remark"
                rows="4"
                :readonly="postNotify.admin_status === 5 ? true : false"
                placeholder="Enter post suggestion for influencer"
                :state="errors.length > 0 ? false : null"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="d-flex justify-content-end mt-4">
            <b-button
              pill
              class="mr-2"
              variant="secondary"
              @click="notifyClose()"
            >Cancel</b-button>
            <b-button
              pill
              :disabled="postNotify.admin_status === 5 ? true : false"
              type="submit"
              variant="primary"
              @click.prevent="sendNotify()"
            >Send</b-button>
          </div>
        </b-form>
      </validation-observer>
      <div
        class="d-block text-center"
        v-if="!postRemarkData.length && !loadingRemark"
      >
        <img
          src="@/assets/images/no-data.png"
          width="150"
        />
        <h5 class="mt-2">Notification History Not Found</h5>
      </div>
      <b-table
        class="mt-4"
        :items="postRemarkData"
        :fields="fieldsemark"
        id="remarkTable"
        :busy="loadingRemark"
        head-variant="light"
        responsive
        hover
        v-else
      >
        <template #table-busy>
          <div class="text-center text-primary mt-5 mb-1">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(post_url)="data">
          <a
            :href="data.value"
            target="_blank"
          >
            {{data.value ? 'Post Link' : ''}}
          </a>
        </template>
        <template #cell(add_time)="data">
          <div style="width:70px">
            {{ data.value | dateFormat}}
          </div>
        </template>
      </b-table>
    </b-modal>
    <!-- Approved Channel Modal -->
    <b-modal
      title="Approved Channel"
      ref="channelModal"
      hide-footer
      :no-close-on-backdrop="true"
    >
      <!-- centered -->
      <div
        class="d-block text-center"
        v-if="!channelList.length"
      >
        <img
          src="@/assets/images/no-data.png"
          width="150"
        />
        <h5 class="mt-2">No Data Found</h5>
      </div>
      <b-table
        :items="channelList"
        :fields="fieldApprove"
        id="channelTable"
        head-variant="light"
        responsive
        hover
        v-else
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(channel_name)="data">
          <a
            v-bind:href="data.item.media_type === 1 ? 'https://www.youtube.com/channel/' + data.item.channel_id : 'https://www.instagram.com/' + data.item.channel_name"
            target="_blank"
          >
            <strong>{{data.item.channel_name}}</strong>
          </a>
        </template>
        <template #cell(action)="data">
          <div class="d-flex justify-content-between">
            <b-icon
              icon="pencil-square"
              variant="primary"
              scale="1.5"
              @click="modalInfluAssign(data.item)"
            ></b-icon>
            <b-icon
              icon="trash"
              scale="1.5"
              variant="danger"
              @click="deleteChannel(data.item)"
            ></b-icon>
          </div>
        </template>
      </b-table>
    </b-modal>
    <!-- Assign Influencer Modal -->
    <b-modal
      title="Influencer Selection"
      ref="influModal"
      hide-footer
      :no-close-on-backdrop="true"
      centered
    >
      <b-form>
        <b-overlay
          :show="loadInlfu"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          @hidden="!loadInlfu"
        >
          <b-form-group label="Change Influencer">
            <b-form-select
              v-model="influData.influencer_id"
              :options="influencerList"
              @change="changeInflu()"
            > <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Influencer</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-overlay>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import constantData from '@/helpers/constant'
import Youtube from './youtube'
import Instagram from './instagram'
import Campaign from './campaign'
import { infDetail } from '@/api/instagram'
import { influDetail } from '@/api/youtube'
import { camPostStatus, campaignPost, postDelete, postUpdate, postAdd, postdata, postApprove, postRemark, campaignChannelDelete, assignInfluencer, campaignStatus } from '@/api/campaign'
export default {
  components: { Youtube, Instagram, Campaign },
  data () {
    return {
      remarkLast: null,
      campaignData: [],
      influModal: false,
      channelList: [],
      postNotify: {},
      influData: {},
      influencerList: [],
      postRemarkData: [],
      notifyPostModal: false,
      loadInlfu: false,
      channelModal: false,
      youtubeModal: false,
      instagramModal: false,
      campaignModal: false,
      platform: null,
      campStatus: null,
      cpv: null,
      totalCost: null,
      totalViews: null,
      profit: null,
      revenue: null,
      editPostModal: false,
      addPostModal: false,
      rejectRemark: null,
      tabledata: [],
      loading: true,
      loadingRemark: false,
      exportLoading: false,
      postType: [{
        value: 0,
        label: 'Image'
      }, {
        value: 1,
        label: 'Video'
      }],
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'channel', label: 'Channel' },
        { key: 'post_link', label: 'Post Url' },
        { key: 'fans', label: 'Followers' },
        { key: 'media', label: 'Total Media' },
        { key: 'count', label: 'Post Views' },
        { key: 'like_count', label: 'Post Likes' },
        { key: 'video_eng_rate', label: 'Post Engage Rate' },
        { key: 'campaign_type', label: 'Campaign Type' },
        { key: 'content', label: 'Content Type' },
        { key: 'cpv', label: 'CPV' },
        { key: 'influencer_price', label: 'Influencer Price' },
        { key: 'tds', label: 'TDS' },
        { key: 'client_price', label: 'Revenue' },
        { key: 'cost', label: 'Total Cost' },
        { key: 'gst', label: 'GST' },
        { key: 'add_by', label: 'Added By' },
        'add_time',
        'action'
      ],
      fieldsemark: [
        { key: 'index', label: 'Sr.' },
        { key: 'post_url', label: 'Post Url' },
        { key: 'remark', label: 'Remark' },
        { key: 'last_action_by', label: 'Operator' },
        { key: 'add_time', label: 'Add Time' }
      ],
      fieldApprove: [
        { key: 'index', label: 'Sr.' },
        { key: 'channel_name', label: 'Channel' },
        { key: 'influencer_name', label: 'Influencer' },
        'action'
      ],
      editPostData: {
      },
      addPostData: {
        tds: null,
        gst: null,
        influencer_price: null,
        client_price: null,
        post_link: null,
        media_id: null,
        campaign_type: null,
        content_type: null,
        like_count: null,
        comment_count: null,
        is_video: null,
        video_duration: null,
        video_play_count: null,
        post_cover: null,
        video_url: null,
        publish_date: null,
        video_view_count: null
      },
      youtubeOptions: constantData.contentYoutube.map(item => {
        return { value: item.value, text: item.label }
      }),
      instagramOptions: constantData.contentInstagram.map(item => {
        return { value: item.value, text: item.label }
      }),
      campaignTypeOptions: constantData.campaignTypeList.map(item => {
        return { value: item.value, text: item.label }
      }),
      instaCategoryListOptions: constantData.instaCategoryList.map(item => {
        return { value: item, text: item }
      }),
      categoryOptions: constantData.category.map(item => {
        return { value: item, text: item }
      }),
      genderOptions: constantData.genderList.map(item => {
        return { value: item, text: item }
      }),
      languageOptions: constantData.language.map(item => {
        return { value: item, text: item }
      }),
      infListOptions: constantData.infListAdmin,
      pageListOptions: constantData.pageList.map(item => {
        return { value: item.value, text: item.label }
      }),
      instaVerifyOptions: constantData.instaVerifyList.map(item => {
        return { value: item.value, text: item.label }
      }),
      AccountTypeOptions: constantData.instaAccountTypeList.map(item => {
        return { value: item.value, text: item.label }
      }),
      campaignStatusOptions: constantData.campaignStatus.map(item => {
        return { value: item.value, text: item.label }
      })
    }
  },
  mounted () {
    this.platform = parseInt(this.$route.params.platform)
    this.campaignPost()
  },
  methods: {
    webshowUpdate (item) {
      try {
        this.$bvModal.msgBoxConfirm(item.web_show ? 'Are are you sure want to live this campaign?' : 'Are are you sure want to offline this campaign?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            actionUser: window.localStorage.getItem('user-name'),
            campaign_id: item.campaign_id,
            web_show: item.web_show ? 1 : 0
          }
          if (value) {
            const response = await campaignStatus(data)
            if (response.succ) {
              this.$bvToast.toast(item.web_show ? 'Campaign Live successfully' : 'Campaign offline successfully', {
                title: 'Live Status',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Live Status error!',
                variant: 'danger',
                solid: true
              })
            }
          } else {
            item.web_show = !item.web_show
          }
        }).catch((error) => {
          console.log('cancel status error.', error)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async modalInfluAssign (data) {
      this.influData = { ...data }
      this.$refs.influModal.show()
      this.loadInlfu = true
      let response
      if (data.media_type === 1) {
        response = await influDetail({ channel_id: data.channel_id })
      } else {
        response = await infDetail({ insta_username: data.channel_id })
      }
      if (response.succ) {
        this.influencerList = response.data
        this.influencerList = this.influencerList.map(item => {
          return { value: item.uuid, text: item.name }
        })
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loadInlfu = false
    },
    deleteChannel (data) {
      try {
        this.$bvModal.msgBoxConfirm('Are are you sure want to delete channel?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          if (value) {
            const response = await campaignChannelDelete(data)
            if (response.succ) {
              this.$bvToast.toast('Channel deleted successfully', {
                title: 'Channel Deleted',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              this.channelList.splice(this.channelList.findIndex(a => a.id === data.id), 1)
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Delete error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch((e) => {
          console.log('cancel delete.', e)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async changeInflu () {
      const name = this.influencerList.filter(item => {
        if (item.value === this.influData.influencer_id) {
          return item
        }
      })
      this.influData.influencer_name = name[0].text
      try {
        const response = await assignInfluencer(this.influData)
        if (response.succ) {
          this.$bvToast.toast('Influencer updated successfully', {
            title: 'Influencer Updated',
            variant: 'success',
            autoHideDelay: 2000,
            solid: true
          })
          const index = this.channelList.findIndex(a => a.id === this.influData.id)
          this.channelList[index].influencer_name = this.influData.influencer_name
          this.$refs.influModal.hide()
        } else {
          this.$bvToast.toast(response.msg, {
            title: 'Update error!',
            variant: 'danger',
            solid: true
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    openChannalModal () {
      this.$refs.channelModal.show()
    },
    closewindow () {
      this.$refs.campaignModal.hide()
    },
    ytModal () {
      this.$bvToast.toast('YouTuber added successfully', {
        title: 'Added Channel',
        variant: 'success',
        autoHideDelay: 1000,
        solid: true
      })
      this.$refs.youtubeModal.hide()
    },
    instModal () {
      this.$bvToast.toast('Instagrammer added successfully', {
        title: 'Added Channel',
        variant: 'success',
        autoHideDelay: 1000,
        solid: true
      })
      this.$refs.instagramModal.hide()
    },
    campaignStatusVarient (value) {
      switch (value) {
        case 0:
          return 'warning'
        case 1:
          return 'success'
        case 2:
          return 'danger'
        case 4:
          return 'info'
        default:
          return 'NA'
      }
    },
    async fetchapi (item, type) {
      if (!item.media_id) {
        this.$bvToast.toast('Please select channel', {
          title: 'Admin error!',
          variant: 'danger',
          solid: true,
          autoHideDelay: 1000
        })
        return
      }
      const filtered = this.channelList.filter(row => {
        if (row.media_id === item.media_id) {
          return row
        }
      })
      const channelId = filtered[0].channel_id
      this.exportLoading = true
      const response = await postdata({ postLink: item.post_link, channelId: channelId })
      if (response.succ) {
        this.$bvToast.toast('Post data fetch successfully', {
          title: 'Instagram Post',
          variant: 'success',
          autoHideDelay: 1000,
          solid: true
        })
        if (type === 1) {
          this.addPostData.like_count = response.data.like_count
          this.addPostData.comment_count = response.data.comment_count
          this.addPostData.is_video = response.data.is_video
          this.addPostData.video_view_count = response.data.video_view_count
          this.addPostData.video_duration = response.data.video_duration
          this.addPostData.video_play_count = response.data.video_play_count
          this.addPostData.publish_date = response.data.publishdate
          this.addPostData.post_cover = response.data.post_cover
          this.addPostData.video_url = response.data.video_url
        } else {
          this.editPostData.like_count = response.data.like_count
          this.editPostData.comment_count = response.data.comment_count
          this.editPostData.is_video = response.data.is_video
          this.editPostData.video_view_count = response.data.video_view_count
          this.editPostData.video_duration = response.data.video_duration
          this.editPostData.video_play_count = response.data.video_play_count
          this.editPostData.publish_date = response.data.publishdate
          this.editPostData.post_cover = response.data.post_cover
          this.editPostData.video_url = response.data.video_url
        }
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Instagram error!',
          variant: 'danger',
          solid: true
        })
      }
      this.exportLoading = false
    },
    assignInfluencer () {
      if (this.platform === 1) {
        this.$refs.youtubeModal.show()
      } else {
        this.$refs.instagramModal.show()
      }
    },
    copyCampaign () {
      this.$refs.campaignModal.show()
    },
    exportdata () {
      this.exportLoading = true
      var InstagramrWS = XLSX.utils.json_to_sheet(this.tabledata)
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, InstagramrWS, 'campaignPostData') // sheetAName is name of Worksheet
      setTimeout(() => {
        XLSX.writeFile(wb, 'campaignpost.xlsx')
        this.exportLoading = false
      }, 2000)
    },
    changeStatus () {
      this.rejectRemark = null
      if (this.campStatus === 0 && this.campaignData.campaign_status === 1) {
        this.$bvToast.toast('Progress status cannot be change to draft', {
          title: 'Status',
          variant: 'warning',
          solid: true
        })
        this.campStatus = this.campaignData.campaign_status
      } else if (this.campaignData.campaign_status === 2) {
        this.$bvToast.toast('Cancelled status cannot be change', {
          title: 'Status',
          variant: 'warning',
          solid: true
        })
        this.campStatus = 2
      } else if (this.campaignData.campaign_status === 4) {
        this.$bvToast.toast('Completed status cannot be change', {
          title: 'Status',
          variant: 'warning',
          solid: true
        })
        this.campStatus = 4
      } else {
        try {
          this.$bvModal.msgBoxConfirm(<b-form-textarea v-show={this.campStatus === 2} size="md" rows="4" placeholder="Remark" v-model={this.rejectRemark}></b-form-textarea>, {
            title: 'Are are you sure want to update status?',
            cancelVariant: 'danger',
            okVariant: 'primary',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            size: 'md',
            buttonSize: 'sm',
            hideHeaderClose: false,
            centered: true
          }).then(async (value) => {
            const data = { campaign_id: this.$route.params.campaignId, status: this.campStatus, actionUser: window.localStorage.getItem('user-name'), remarkDesc: this.rejectRemark }
            if (value) {
              const response = await camPostStatus(data)
              if (response.succ) {
                this.$bvToast.toast('Campaign status changed', {
                  title: 'Status',
                  variant: 'success',
                  autoHideDelay: 2000,
                  solid: true
                })
                this.campaignData.campaign_status = this.campStatus
                this.remarkLast = this.rejectRemark
              } else {
                this.$bvToast.toast(response.msg, {
                  title: 'Status error!',
                  variant: 'danger',
                  solid: true
                })
              }
            } else {
              this.campStatus = this.campaignData.campaign_status
            }
          }).catch((error) => {
            console.log('cancel update.', error)
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    postModalAdd () {
      this.addPostData.campaign_type = this.campaignData.campaign_type
      this.addPostData.content_type = this.campaignData.content_type
      this.$refs.addPostModal.show()
    },
    postModalEdit (row) {
      this.editPostData = { ...row }
      this.editPostData.campaign_type = this.campaignData.campaign_type
      this.editPostData.content_type = this.campaignData.content_type
      this.$refs.editPostModal.show()
    },
    cancelAdd () {
      this.resetPost()
      this.$refs.addPostModal.hide()
    },
    cancelEdit () {
      this.$refs.editPostModal.hide()
    },
    resetPost () {
      this.addPostData = {
        campaign_type: this.campaignData.campaign_type,
        content_type: this.campaignData.content_type,
        tds: null,
        gst: null,
        influencer_price: null,
        client_price: null,
        post_link: null,
        media_id: null,
        like_count: null,
        comment_count: null,
        is_video: null,
        video_duration: null,
        video_play_count: null,
        post_cover: null,
        video_url: null,
        publish_date: null,
        video_view_count: null
      }
      this.exportLoading = false
    },
    async campaignPost () {
      this.loading = true
      const data = {
        campaignId: this.$route.params.campaignId,
        platform: this.platform
      }
      const response = await campaignPost(data)
      if (response.succ) {
        this.tabledata = response.data
        this.remarkLast = response.remarkLast
        this.campaignData = response.campaignData[0]
        this.campaignData.web_show = !!this.campaignData.web_show
        this.channelList = response.influencerList
        this.campStatus = this.campaignData.campaign_status
        this.totalViews = this.tabledata.map(element => parseInt(element.view_count ? element.view_count : 0)).reduce((a, b) => a + b, 0)
        this.likeCount = this.tabledata.map(element => parseInt(element.like_count ? element.like_count : 0)).reduce((a, b) => a + b, 0)
        this.totalCost = this.campaignData.influencerprice + this.campaignData.tds
        this.totalCost = this.totalCost ? this.totalCost : 0
        const dislikeCount = this.tabledata.map(element => element.dislike_count ? element.dislike_count : 0).reduce((a, b) => a + b, 0)
        const comment = this.tabledata.map(element => element.comment_count ? element.comment_count : 0).reduce((a, b) => a + b, 0)
        const viewCount = this.tabledata.map(element => element.view_count ? element.view_count : 0).reduce((a, b) => a + b, 0)
        this.engageRate = ((this.likeCount + dislikeCount + comment) / viewCount) * 100
        this.engageRate = this.engageRate ? this.engageRate.toFixed(2) : 0
        this.revenue = this.tabledata.map(element => element.client_price ? element.client_price : 0).reduce((a, b) => a + b, 0)
        this.revenue = this.campaignData.clientprice
        this.profit = this.revenue - this.totalCost
        if (this.platform === 1) {
          this.cpv = this.totalCost && viewCount > 0 ? (this.totalCost / viewCount) : 0
        } else {
          this.cpv = this.totalCost && this.likeCount > 0 ? (this.totalCost / this.likeCount) : 0
        }
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async deletePost (item) {
      try {
        this.$bvModal.msgBoxConfirm('Are are you sure want to delete post?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            id: item.icid,
            campaign_id: item.campaign_id,
            actionUser: window.localStorage.getItem('user-name'),
            media_id: item.media_id,
            influencer_id: item.influencer_id,
            channelUrl: item.media_type === 1 ? item.channel_link : item.insta_link,
            postUrl: item.post_link,
            media_type: item.media_type,
            client_id: this.campaignData.client_id
          }
          if (value) {
            const response = await postDelete(data)
            if (response.succ) {
              this.$bvToast.toast('Post deleted successfully', {
                title: 'Post Deleted',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              this.tabledata.splice(this.tabledata.findIndex(a => a.icid === item.icid), 1)
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Delete error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch(() => {
          console.log('cancel delete.')
        })
      } catch (error) {
        console.log(error)
      }
    },
    async updatePost () {
      this.$refs.editRules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to update this post?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const filtered = this.channelList.filter(row => {
                if (row.media_id === this.editPostData.media_id) {
                  return row
                }
              })
              const channelId = filtered[0].channel_id
              const data = { client_id: this.campaignData.client_id, media_type: this.editPostData.media_type, campaign_type: this.campaignData.campaign_type, content_type: this.campaignData.content_type, channel_id: channelId, id: this.editPostData.icid, actionUser: window.localStorage.getItem('user-name'), post_link: this.editPostData.post_link, tds: this.editPostData.tds, gst: this.editPostData.gst, client_price: this.editPostData.client_price, influencer_price: this.editPostData.influencer_price, media_id: this.editPostData.media_id, like_count: this.editPostData.like_count, comment_count: this.editPostData.comment_count, is_video: this.editPostData.is_video, video_view_count: this.editPostData.video_view_count, video_duration: this.editPostData.video_duration, video_play_count: this.editPostData.video_play_count, publish_date: this.editPostData.publish_date, post_cover: this.editPostData.post_cover, video_url: this.editPostData.video_url, influencer_id: this.editPostData.influencer_id, channelUrl: this.editPostData.media_type === 1 ? this.editPostData.channel_link : this.editPostData.insta_link, campaign_id: this.editPostData.campaign_id }
              if (value) {
                const response = await postUpdate(data)
                if (response.succ) {
                  this.$bvToast.toast('Post updated successfully', {
                    title: 'Updated Post',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.campaignPost()
                  this.$refs.editPostModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Update error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch((error) => {
              console.log('cancel update.', error)
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async addPost () {
      this.$refs.addchannelrules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to add this post?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const filtered = this.channelList.filter(row => {
                if (row.media_id === this.addPostData.media_id) {
                  return row
                }
              })
              const channelId = filtered[0].channel_id
              const data = {
                client_id: this.campaignData.client_id, campaign_id: this.$route.params.campaignId, media_type: this.platform, campaign_type: this.campaignData.campaign_type, content_type: this.campaignData.content_type, channel_id: channelId, actionUser: window.localStorage.getItem('user-name'), post_link: this.addPostData.post_link, tds: this.addPostData.tds, gst: this.addPostData.gst, client_price: this.addPostData.client_price, influencer_price: this.addPostData.influencer_price, media_id: this.addPostData.media_id, like_count: this.addPostData.like_count, comment_count: this.addPostData.comment_count, is_video: this.addPostData.is_video, video_view_count: this.addPostData.video_view_count, video_duration: this.addPostData.video_duration, video_play_count: this.addPostData.video_play_count, publish_date: this.addPostData.publish_date, post_cover: this.addPostData.post_cover, video_url: this.addPostData.video_url
              }
              if (value) {
                const response = await postAdd(data)
                if (response.succ) {
                  this.$bvToast.toast('Post added successfully', {
                    title: 'Added Post',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.campaignPost()
                  this.resetPost()
                  this.$refs.addPostModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Add post error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch((error) => {
              console.log('cancel add.', error)
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async postApproval (item) {
      try {
        const data = {
          id: item.icid,
          actionUser: window.localStorage.getItem('user-name'),
          status: 5,
          remark: 'Post approved',
          campaignId: item.campaign_id,
          postUrl: item.post_link,
          channelUrl: item.media_type === 1 ? item.channel_link : item.insta_link,
          influencerId: item.influencer_id,
          media_id: item.media_id,
          client_id: this.campaignData.client_id
        }
        const response = await postApprove(data)
        if (response.succ) {
          this.$bvToast.toast('Post approved successfully', {
            title: 'Post Approval',
            variant: 'success',
            autoHideDelay: 2000,
            solid: true
          })
          const index = this.tabledata.findIndex(a => a.icid === item.icid)
          this.tabledata[index].admin_status = 5
          this.tabledata[index].remark = 'Post approved'
        } else {
          this.$bvToast.toast('Some error occured while approve', {
            title: 'Approval error!',
            variant: 'danger',
            solid: true
          })
        }
      } catch (error) {
        console.log(error)
        this.$bvToast.toast('Something went wrong', {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    async sendNotify () {
      this.$refs.postRules.validate().then(async success => {
        if (success) {
          try {
            const data = {
              id: this.postNotify.id,
              actionUser: window.localStorage.getItem('user-name'),
              status: 4,
              remark: this.postNotify.remark,
              postUrl: this.postNotify.post_url,
              campaignId: this.postNotify.campaign_id,
              channelUrl: this.postNotify.channelUrl,
              influencerId: this.postNotify.influencer_id,
              media_id: this.postNotify.media_id,
              client_id: this.campaignData.client_id
            }
            const response = await postApprove(data)
            if (response.succ) {
              this.$bvToast.toast('Notification sent successfully', {
                title: 'Notification',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              const index = this.tabledata.findIndex(a => a.icid === this.postNotify.id)
              this.tabledata[index].admin_status = 4
              this.tabledata[index].remark = this.postNotify.remark
              this.postremarkList()
              this.$refs.notifyPostModal.hide()
            } else {
              this.$bvToast.toast('Some error occured while notify', {
                title: 'Notification error!',
                variant: 'danger',
                solid: true
              })
            }
          } catch (error) {
            console.log(error)
            this.$bvToast.toast('Something went wrong', {
              title: 'Server error!',
              variant: 'danger',
              solid: true
            })
          }
        }
      })
    },
    async postremarkList () {
      this.loadingRemark = true
      const response = await postRemark({ id: this.postNotify.id })
      if (response.succ) {
        this.postRemarkData = response.data
      } else {
        this.$bvToast.toast('Something went wrong', {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loadingRemark = false
    },
    openNotify (data) {
      this.postNotify.id = data.icid
      this.postNotify.campaign_id = data.campaign_id
      this.postNotify.remark = data.remark
      this.postNotify.post_url = data.post_link
      this.postNotify.channelUrl = data.media_type === 1 ? data.channel_link : data.insta_link
      this.postNotify.admin_status = data.admin_status
      this.postNotify.influencer_id = data.influencer_id
      this.postNotify.campaign_id = data.campaign_id
      this.postNotify.media_id = data.media_id
      this.$refs.notifyPostModal.show()
      this.postremarkList()
    },
    notifyClose () {
      this.$refs.notifyPostModal.hide()
    }
  }
}
</script>

<style scoped>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
  .table td span.badge {
    font-size: 10px;
    line-height: 10px;
    padding: 2px 5px;
    /* border: 1px solid #f54153;
                                                                        color: #f54153; */
    border-radius: 20px;
    margin: 3px 0 0 0;
    display: inline-block;
  }
  .statBox {
    border: 1px dashed #ccc;
    padding: 8px 10px 5px;
    border-radius: 5px;
    margin: 0 15px 0 0;
  }
  .statBox h5 {
    display: inline-block;
  }
  .statBox small {
    color: #aaa;
    font-size: 13px;
  }
  /* .badge {
                                    font-size: 12px;
                                    position: relative;
                                    top: -2px;
                                  } */
  .b-icon:hover {
    cursor: pointer;
  }
  .infocard strong {
    line-height: normal;
    font-size: 14px;
  }
  .infocard p small {
    color: #818588;
    margin: 0 15px 0 0;
    font-size: 13px;
  }
  .infocard p span {
    font-size: 13px;
  }
  .infocard p {
    margin: 0;
    line-height: normal;
    font-size: 14px;
  }
  .infocard p span.label {
    font-size: 13px;
    margin: 0 5px 0 0;
  }
  .link {
    color: #818588;
  }
  .link:hover {
    color: #4c62e3;
    text-decoration: none;
  }
</style>
