<template>
  <div>
    <b-card no-body>
      <b-tabs card v-model="tabIndex">
        <b-tab title="Campaign Details">
          <CampaignDetail v-if="tabIndex===0"/>
        </b-tab>
        <b-tab>
          <template #title>
            Campaign applied by Influencers
            <b-badge
              v-if="pendingApplicationsInfluencer > 0"
              variant="warning"
            >
              {{pendingApplicationsInfluencer}}
            </b-badge>
          </template>
          <InfluencerCampaign  v-if="tabIndex===1" v-on:influencerPendingdata="influencerPending"/>
        </b-tab>
        <b-tab>
          <template #title>
            Influencers selected by Brand
            <b-badge
              v-if="pendingApplicationsBrand > 0"
              variant="warning"
            >
              {{pendingApplicationsBrand}}
            </b-badge>
          </template>
          <BrandInfluencer v-if="tabIndex===2"  v-on:brandPendingdata="brandPending"/>
        </b-tab>
        <b-tab title="Short Url">
          <ShortUrl v-if="tabIndex===3"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import BrandInfluencer from './components/brandInfluencer.vue'
import InfluencerCampaign from './components/influencerCampaign.vue'
import CampaignDetail from './components/campaignDetail.vue'
import ShortUrl from '../shorturl/Index.vue'
export default {
  components: { InfluencerCampaign, BrandInfluencer, CampaignDetail, ShortUrl },
  data () {
    return {
      pendingApplicationsInfluencer: Number(this.$route.query.pendingApplicationsInfluencer) || 0,
      pendingApplicationsBrand: Number(this.$route.query.pendingApplicationsBrand) || 0,
      // pendingApplicationsInfluencer: null,
      // pendingApplicationsBrand: null,
      tabIndex: 0
    }
  },
  methods: {
    influencerPending (influencerLength) {
      this.pendingApplicationsInfluencer = influencerLength
    },
    brandPending (brandLength) {
      this.pendingApplicationsBrand = brandLength
    }
  }
}
</script>

<style scoped>
</style>
