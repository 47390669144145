<template>
  <div>
    <!-- Data Table -->
    <div
      class="d-block text-center"
      v-if="!tabledata.length && !loading"
    >
      <img
        src="@/assets/images/no-data.png"
        width="150"
      />
      <h5 class="mt-2">No Record Found</h5>
    </div>
    <b-table
      :items="tabledata"
      :fields="fields"
      id="tabledata"
      :busy="loading"
      head-variant="light"
      responsive
      hover
      v-else
    >
      <template #table-busy>
        <div class="text-center text-primary mt-5 mb-1">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(chName)="data">
        <a
          v-bind:href="data.item.type === 1 ? data.item.channel_link : data.item.insta_link"
          target="_blank"
        >
          <strong style="text-transform: capitalize;">{{
            data.item.type === 1 ? data.item.channel_name :
              data.item.insta_name
          }}</strong>
        </a><br />
        <b-badge
          variant="danger"
          v-if="data.item.status === 2"
        >
          Rejected
        </b-badge>
        <b-badge
          :variant="`${data.item.status === 1 ? 'success' : 'warning'}`"
          v-else
        >
          {{ data.item.status === 1 ? 'Approved' : 'Pending' }}
        </b-badge>
      </template>
      <template #cell(name)="data">
        {{ data.value }}
      </template>
      <template #cell(add_time)="data">
        <div style="min-width: 80px;">
          {{ data.value | dateFormat }}
        </div>
      </template>
      <!-- <template #cell(type)="data">
        {{ data.value | platform }}
      </template> -->
      <template #cell(campaign_type)="data">
        {{ data.value | campaignType }}
      </template>
      <template #cell(content)="data">
        <div v-if="data.item.type === 1">
          {{ data.item.content_type | youtube }}
        </div>
        <div v-else>{{ data.item.content_type | instagram }} </div>
      </template>
      <template #cell(budget)="data">
        {{ data.value | formatNumber }}
      </template>
      <template #cell(min_follower)="data">
        <div style="min-width: 80px;">
          {{ data.value | formatNumber }}
        </div>
      </template>
      <template #cell(fans)="data">
        <div
          style="min-width: 80px;"
          v-if="data.item.type === 1"
        >
          {{ data.item.subscribers | formatNumber }}
        </div>
        <div v-else>{{ data.item.followers | formatNumber }} </div>
      </template>
      <template #cell(media)="data">
        <div
          style="min-width: 80px;"
          v-if="data.item.type === 1"
        >
          {{ data.item.total_video | formatNumber }}
        </div>
        <div v-else>{{ data.item.total_media | formatNumber }} </div>
      </template>
      <template #cell(action)="data">
        <b-dropdown
          variant="outline-white"
          no-caret
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical"></b-icon>
          </template>
          <b-dropdown-item @click="modal(data.item)">
            <b-icon
              variant="primary"
              icon="person-plus"
            ></b-icon>
            {{data.item.name ? 'Assigned' : 'Assign'}} Influencer
          </b-dropdown-item>
          <b-dropdown-item
            @click="updateStatus(data.item, 1)"
            :disabled="data.item.status === 1"
          >
            <b-icon
              variant="success"
              icon="person-check"
            ></b-icon>
            {{ data.item.status === 1 ? 'Approved' : 'Approve' }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="updateStatus(data.item, 2)"
            :disabled="data.item.status === 2"
          >
            <b-icon
              variant="danger"
              icon="person-x"
            ></b-icon>
            {{ data.item.status === 2 ? 'Rejected' : 'Reject' }}
          </b-dropdown-item>
        </b-dropdown>
        <!-- <div class="d-flex">
          <b-button
            pill
            size="sm"
            class="mr-2"
            style="width:100px;border: 1px solid #28a745;"
            :disabled="data.item.status === 1 ? true : false"
            variant="outline-success"
            @click="updateStatus(data.item, 1)"
          >{{data.item.status === 1 ? 'Approved' : 'Approve'}}</b-button>
          <b-button
            pill
            size="sm"
            style="width:100px;border: 1px solid #dc3545;"
            :disabled="data.item.status === 2 ? true : false"
            variant="outline-danger"
            @click="updateStatus(data.item, 2)"
          > {{data.item.status === 2 ? 'Rejected' : 'Reject'}}</b-button>
        </div> -->
      </template>
    </b-table>
    <b-pagination
      v-if="total > 20 && !loading"
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      align="center"
      pills
      @input="handlePageClick"
    ></b-pagination>
    <b-modal
      ref="influencerModal"
      hide-footer
    >
      <template #modal-title>
        <div class="modalheader">
          Influencer assign to channel <span><a
              :href="modalData.channelLink"
              target="_blank"
            >
              {{ modalData.channelName }}</a></span></div>
      </template>
      <validation-observer ref="addrules">
        <b-form>
          <b-form-group label="Influencer">
            <validation-provider
              #default="{ errors }"
              name="Influencer"
              rules="required"
            >
              <b-form-select
                v-model="modalData.uuid"
                :options="influencerList"
                :state="errors.length > 0 ? false : null"
              > <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Influencer</b-form-select-option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="d-flex justify-content-end mt-4">
            <b-button
              pill
              class="mr-2"
              variant="secondary"
              @click="cancel"
            >Cancel</b-button>
            <b-button
              pill
              class="mr-2"
              variant="primary"
              @click="submit"
            >Submit</b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { brandinflustatus, brandinfluList, assignInfluencer } from '@/api/campaign'
import { infDetail } from '@/api/instagram'
import { influDetail } from '@/api/youtube'
export default {
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      influencerModal: false,
      modalData: {},
      pendingApproval: null,
      influencer_remark: null,
      client_remark: null,
      influencerList: [],
      tabledata: [],
      loading: true,
      total: 0,
      filter: {
        p: 1,
        size: 20
      },
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'chName', label: 'Channel Name' },
        { key: 'name', label: 'Influencer Name' },
        // { key: 'type', label: 'Platform' },
        { key: 'fans', label: 'Followers' },
        { key: 'media', label: 'Total Media' },
        { key: 'campaign_type', label: 'Campaign Type' },
        { key: 'content', label: 'Content Type' },
        { key: 'budget', label: 'Campaign Budget' },
        { key: 'min_follower', label: 'Campaign Min Follower' },
        { key: 'add_time', label: 'Add time' },
        'action'
      ]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    async getData () {
      this.loading = true
      this.filter.campaignId = this.$route.params.campaignId
      this.filter.platform = parseInt(this.$route.params.platform)
      const response = await brandinfluList(this.filter)
      this.loading = false
      if (response.succ) {
        this.total = response.totaluser
        this.tabledata = response.data
        this.pendingApproval = this.tabledata.filter(value => value.status === 0)
        const brandLength = this.pendingApproval.length
        this.$emit('brandPendingdata', brandLength)
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    async updateStatus (item, status) {
      try {
        this.$bvModal.msgBoxConfirm(<div><b-form-textarea size="md" rows="4" placeholder="Remark For Influencer" v-model={this.influencer_remark}></b-form-textarea><b-form-textarea class="mt-4" size="md" rows="4" placeholder="Remark For Brand" v-model={this.client_remark}></b-form-textarea></div>, {
          title: status === 1 ? 'Are you sure want to approve?' : 'Are you sure want to reject?',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'md',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            influencer_remark: this.influencer_remark,
            client_id: this.$route.query.client,
            client_remark: this.client_remark,
            actionUser: window.localStorage.getItem('user-name'),
            id: item.id,
            channelId: item.type === 1 ? item.channel_link.replace('https://www.youtube.com/channel/', '') : item.insta_link.replace('https://www.instagram.com/', ''),
            channelUrl: item.type === 1 ? item.channel_link : item.insta_link,
            uuid: item.uuid,
            campaign_id: item.campaign_id,
            media_id: item.influencer_id,
            media_type: item.type,
            influencer_name: item.name,
            channel_name: item.type === 1 ? item.channel_name : item.insta_name,
            campaign_type: item.campaign_type,
            content_type: item.content_type,
            status: status
          }
          if (value) {
            this.loading = true
            const response = await brandinflustatus(data)
            this.loading = false
            if (response.succ) {
              this.$bvToast.toast(status === 1 ? 'Approved successfully' : 'Rejected successfully', {
                title: 'Status',
                variant: status === 1 ? 'success' : 'danger',
                autoHideDelay: 2000,
                solid: true
              })
              this.influencer_remark = null
              this.client_remark = null
              this.getData()
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Status error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch(() => {
          console.log('cancel update.')
        })
      } catch (error) {
        console.log(error)
      }
    },
    influencerName (uuid) {
      const NameArr = []
      const influName = this.influencerList.find(
        ar => ar.uuid === uuid
      )
      if (influName) {
        NameArr.push(influName.text)
      } else {
        NameArr.push('NA')
      }
      return NameArr.join()
    },
    async modal (data) {
      this.modalData = {}
      this.modalData = { ...data }
      this.modalData.channelName = data.type === 1 ? data.channel_name : data.insta_name
      this.modalData.media_type = data.type
      this.modalData.channelLink = data.type === 1 ? data.channel_link : data.insta_link
      this.modalData.channelId = data.type === 1 ? (data.channel_link.replace('https://www.youtube.com/channel/', '')) : (data.insta_link.replace('https://www.instagram.com/', ''))
      this.$refs.influencerModal.show()
      let response
      if (this.modalData.type === 1) {
        response = await influDetail({ channel_id: this.modalData.channelId })
      } else {
        response = await infDetail({ insta_username: this.modalData.channelId })
      }
      if (response.succ) {
        this.influencerList = response.data
        this.influencerList = this.influencerList.map(item => {
          return { value: item.uuid, text: item.name }
        })
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    submit () {
      this.$refs.addrules.validate().then(async success => {
        if (success) {
          try {
            const data = {
              brandId: this.modalData.id,
              uuid: this.modalData.uuid
              // name: this.modalData.name
              // campaign_id: this.$route.params.campaignId,
              // media_id: this.modalData.influencer_id,
              // media_type: this.modalData.media_type
            }
            const response = await assignInfluencer(data)
            if (response.succ) {
              this.$bvToast.toast('Influencer assigned successfully', {
                title: 'Updated',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              const influName = this.influencerList.filter(item => { return item.value === this.modalData.uuid })
              const index = this.tabledata.findIndex(a => a.id === this.modalData.id)
              this.tabledata[index].name = influName[0].text
              this.tabledata[index].uuid = influName[0].value
              this.$refs.influencerModal.hide()
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Update error!',
                variant: 'danger',
                solid: true
              })
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    cancel () {
      this.$refs.influencerModal.hide()
    }
  }
}
</script>

<style scoped>
  .card-body >>> .table {
    font-size: 14px;
  }

  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }

  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }

  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }

  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }

  .modalheader {
    font-size: 15px !important;
  }
</style>
