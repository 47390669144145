<template>
  <div>
    <!-- Filter Form -->
    <div class="d-flex align-items-start filter-form">
      <div>
        <b-form
          class="mb-2"
          inline
        >

          <b-form-select
            class="mb-2 mr-sm-2"
            v-model="filter.searchType"
            :options="searchOptions"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >Search Type</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-input
            class="mb-2 mr-sm-2"
            v-model="filter.searchValue"
            placeholder="Search Input"
          ></b-form-input>

          <b-form-select
            class="mb-2 mr-sm-2"
            v-model="filter.category"
            :options="instaCategoryOptions"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >Category</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-select
            class="mb-2 mr-sm-2"
            v-model="filter.language"
            :options="languageOptions"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >language</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-select
            class="mb-2 mr-sm-2"
            v-model="filter.gender"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >Select Gender</b-form-select-option>
            </template>
            <b-form-select-option value="Male">Male</b-form-select-option>
            <b-form-select-option value="Female">Female</b-form-select-option>
            <b-form-select-option value="Animation">Animation</b-form-select-option>
            <b-form-select-option value="Group">Group</b-form-select-option>
          </b-form-select>
          <!-- <b-form-select
            class="mb-2 mr-sm-2"
            v-model="filter.is_business"
            :options="AccountTypeOptions"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >Select Account Type</b-form-select-option>
            </template>
          </b-form-select> -->

          <b-form-select
            class="mb-2 mr-sm-2"
            v-model="filter.is_verify"
            :options="instaVerifyOptions"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >Select Account Verify</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select
            class="mb-2 mr-sm-2"
            v-model="filter.is_page"
            :options="pageListOptions"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >Select Account Page</b-form-select-option>
            </template>
          </b-form-select>
          <b-input-group
            prepend="Followers"
            class="mr-sm-2"
          >
            <b-form-input
              v-model="filter.minSubscibers"
              placeholder="Min"
              style="width: 100px;"
            ></b-form-input>
            <b-form-input
              v-model="filter.maxSubscibers"
              placeholder="Max"
              style="width: 100px;"
            ></b-form-input>
          </b-input-group>
          <b-input-group
            prepend="Price"
            class="mr-sm-2"
          >
            <b-form-input
              v-model="filter.minPrice"
              placeholder="Min"
              style="width: 100px;"
            ></b-form-input>
            <b-form-input
              v-model="filter.maxPrice"
              placeholder="Max"
              style="width: 100px;"
            ></b-form-input>
          </b-input-group>
        </b-form>

        <b-form inline>

          <b-button
            pill
            class="mt-lg-0 mt-md-2 mr-sm-2"
            variant="primary"
            @click="search()"
          >Search</b-button>
          <b-button
            pill
            class="mt-lg-0 mt-md-2 mr-sm-2"
            variant="primary"
            @click="resetFilter()"
          >Reset</b-button>
          <b-overlay
            :show="loadbtn"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="submitInfluencer()"
            >Submit</b-button>
          </b-overlay>
        </b-form>
      </div>
    </div>

    <hr />

    <!-- Data Table -->
    <div
      class="d-block text-center"
      v-if="!tabledata.length && !loading"
    >
      <img
        src="@/assets/images/no-data.png"
        width="150"
      />
      <h5 class="mt-2">No Record Found</h5>
    </div>
    <b-table
      :items="tabledata"
      :fields="fields"
      id="tabledata"
      :busy="loading"
      head-variant="light"
      responsive
      hover
      ref="selectableTable"
      selectable
      @row-selected="onRowSelected"
      v-else
    >
      <template #table-busy>
        <div class="text-center text-primary mt-5 mb-1">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template #cell(basic)="data">
        <a
          :href="data.item.insta_link"
          target="_blank"
          class="meta d-flex justify-content-start align-items-center"
        >
          <img :src="`https://ui-avatars.com/api/?background=F54153&color=fff&name=${data.item.insta_name}`" />
          <p>
            <strong>{{ data.item.insta_name }}</strong> <br>
            <b-badge
              pill
              variant="info"
              v-if="data.item.category"
            >{{ data.item.category }}</b-badge>
          </p>
        </a>
      </template>
      <template #cell(language)="data">
        {{ data.value || 'NA' }}
      </template>
      <template #cell(gender)="data">
        {{ data.value || 'NA' }}
      </template>
      <template #cell(followers)="data">
        {{ data.value | formatNumber}}
      </template>
      <!-- <template #cell(is_business)="data">
        {{ data.value | accountType}}
      </template> -->
      <template #cell(total_media)="data">
        {{ data.value | formatNumber}}
      </template>
      <template #cell(is_verify)="data">
        {{ data.value | verify}}
      </template>
      <template #cell(is_page)="data">
        {{ data.value | page}}
      </template>
      <template #cell(contact)="data">
        <div v-if="data.item.contact_1 && data.item.contact_1 !== ''">
          {{ data.item.contact_1 }}
        </div>
        <div v-else-if="data.item.contact_2 && data.item.contact_2 !== ''">
          {{ data.item.contact_2 }}
        </div>
        <div v-else>NA</div>
      </template>
      <template #cell(email)="data">
        <div
          v-if="data.item.email_1 && data.item.email_1 !== ''"
          style="min-width: 120px;"
        >
          {{ data.item.email_1 }}
        </div>
        <div
          v-else-if="data.item.email_2 && data.item.email_2 !== ''"
          style="min-width: 120px;"
        >
          {{ data.item.email_2 }}
        </div>
        <p v-else>NA</p>
      </template>
      <template #cell(price)="data">
        {{ data.value | formatNumber}}
      </template>
    </b-table>
    <b-pagination
      v-if="total > 50 && !loading"
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      align="center"
      pills
      @input="handlePageClick"
    ></b-pagination>
  </div>
</template>

<script>
import constantData from '@/helpers/constant'
import { instaList, assignInsta } from '@/api/campaign'
export default {
  props: {
    CampaignType: {
      type: Number,
      default: () => null
    },
    ContentType: {
      type: Number,
      default: () => null
    }
  },
  data () {
    return {
      selectMode: 'multi',
      loadbtn: false,
      selected: [],
      perPage: 50,
      currentPage: 1,
      tabledata: [],
      loading: true,
      total: 0,
      filter: {
        searchType: null,
        category: null,
        searchValue: null,
        language: null,
        gender: null,
        is_page: null,
        // is_business: null,
        is_verify: null,
        p: 1,
        size: 50
      },
      instagramSelection: [],
      fields: [
        'Selected',
        { key: 'basic', label: 'Channel' },
        'language',
        'gender',
        { key: 'followers', label: 'Followers' },
        { key: 'total_media', label: 'Total Media' },
        // { key: 'is_business', label: 'Account Type' },
        { key: 'is_verify', label: 'Account Verify' },
        { key: 'is_page', label: 'Account Page' },
        { key: 'contact', label: 'Contact' },
        { key: 'email', label: 'Email' },
        'price'
      ],
      searchOptions: [
        { value: 'channel_name', text: 'Channel Name' },
        { value: 'channel_url', text: 'Channel URL' },
        { value: 'contact', text: 'Contact' },
        { value: 'email', text: 'Email' }
      ],
      instaCategoryOptions: constantData.instaCategoryList.map(item => {
        return { value: item, text: item }
      }),
      genderOptions: constantData.genderList.map(item => {
        return { value: item, text: item }
      }),
      languageOptions: constantData.language.map(item => {
        return { value: item, text: item }
      }),
      infListOptions: constantData.infListAdmin,
      pageListOptions: constantData.pageList.map(item => {
        return { value: item.value, text: item.label }
      }),
      instaVerifyOptions: constantData.instaVerifyList.map(item => {
        return { value: item.value, text: item.label }
      }),
      AccountTypeOptions: constantData.instaAccountTypeList.map(item => {
        return { value: item.value, text: item.label }
      })
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    onRowSelected (items) {
      const ids = items.map(item => { return item.id })
      this.instagramSelection = ids
    },
    resetFilter () {
      this.currentPage = 1
      this.filter = {
        searchType: null,
        category: null,
        language: null,
        searchValue: null,
        gender: null,
        p: 1,
        size: 50,
        is_page: null,
        // is_business: null,
        is_verify: null
      }
      this.getData()
    },
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    search () {
      if (this.filter.searchValue && !this.filter.searchType) {
        return this.$bvToast.toast('Please select search type', {
          title: 'Search error!',
          variant: 'danger',
          solid: true
        })
      }
      this.currentPage = 1
      this.filter.p = 1
      this.getData()
    },
    async getData () {
      if (this.instagramSelection.length) {
        this.$refs.selectableTable.clearSelected()
      }
      this.loading = true
      this.filter.platform = parseInt(this.$route.params.platform)
      this.filter.campaign_id = this.$route.params.campaignId
      const response = await instaList(this.filter)
      if (response.succ) {
        this.tabledata = response.data
        this.total = response.totaluser
        this.instagramSelection = this.tabledata.filter((item, index) => item.assignYt === '1')
        setTimeout(() => {
          this.tabledata.forEach((row, index) => {
            if (row.assignYt === '1') {
              this.$refs.selectableTable.selectRow(index)
            }
          })
        }, 20)
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async submitInfluencer () {
      // if (!this.instagramSelection.length) {
      //   this.$bvToast.toast('Please select instagrammer', {
      //     title: 'Add channel error!',
      //     variant: 'danger',
      //     autoHideDelay: 1000,
      //     solid: true
      //   })
      //   return
      // }
      try {
        this.$bvModal.msgBoxConfirm('Are are you sure want to add this instagrammer?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            actionUser: window.localStorage.getItem('user-name'),
            campaignId: this.$route.params.campaignId,
            instaIds: this.instagramSelection,
            media_type: parseInt(this.$route.params.platform),
            campaign_type: this.CampaignType,
            content_type: this.ContentType,
            filter: this.filter,
            client_id: this.$route.query.client
          }
          if (value) {
            this.loadbtn = true
            const response = await assignInsta(data)
            if (response.succ) {
              // this.getData()
              this.$emit('closeInst')
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Add channel error!',
                variant: 'danger',
                solid: true
              })
            }
            this.loadbtn = false
          }
        }).catch((error) => {
          console.log('cancel add.', error)
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
</style>
